import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const PFarmPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			farmsBg: file(relativePath: { eq: "backgrounds/snow-covered-fields.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Painesend Farm"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={psColour.hex}
					link={`/painesend/our-farms`}/>
				<Split>
					<ImageBlock 
						image={ data.farmsBg.childImageSharp.fluid }/>
					<TextBlock 
						title={`Painesend Farm, Tring`}
						titleTag={ `h1` }
						text={`
							<p>Painesend Farm is tucked away at the foot of the Chiltern Hills in an Area of Outstanding Natural Beauty. The light and permeable chalk land lends itself to spring cultivation, whilst the rolling hills provide the perfect topography for pheasant and partridge shooting.  Sheep graze happily on the permanent pasture.</p>
						`}
						 bgColour={ psColour.slug }/>
				</Split>
			</Layout>
		</>
	)
}

export default PFarmPage
